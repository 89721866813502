import { JobCard } from 'ui/atoms'
import { Job } from 'api/types'
import classnames from 'classnames'

interface JobListProps {
  jobs: Job[]
  title?: string
  className?: string
}

export function JobList({ jobs, title = '', className = '' }: JobListProps) {
  return (
    <div className={classnames('container', className)}>
      <h1 className='font-bold text-center text-3xl md:text-4xl md:text-left mb-5'>{title}</h1>
      {jobs.length ? (
        jobs.map((job) => <JobCard key={job.id} {...job} />)
      ) : (
        <h3 className='mx-auto text-xl'>No jobs found</h3>
      )}
    </div>
  )
}
