import { Service, useList, useRead, SWRConfiguration } from 'api/common'
import type { ListParams, ReadParams, Location } from './types'
import { jobsSite } from 'lib/env'

export class LocationService extends Service<Location> {
  constructor() {
    super('/locations')
  }

  override read(id: string, params?: ReadParams) {
    return super.read(id, { ...params, sites: jobsSite })
  }

  override list(params?: ListParams<Location>) {
    return super.list({ ...params, sites: jobsSite })
  }
}

export const locationService = new LocationService()

export function useLocations(params?: ListParams<Location>, config?: SWRConfiguration) {
  return useList(locationService, params, config)
}

export function useLocation(id: string, params?: ListParams<Location>, config?: SWRConfiguration) {
  return useRead(locationService, id, params, config)
}
