import { TextInput } from 'ui/atoms'
import { useEffect, useState } from 'react'
import { Job, ListParams } from 'api/types'
import { CheckboxInput } from 'ui/atoms'
import { useCheckedInput, useTextInput, useDebouncedInput } from 'ui/hooks/input'
import { jobService } from 'api/job'
import { AutocompleteInput } from 'ui/atoms/autocomplete-input'
import { locationService } from 'api/location'
import { isNull, isUndefined, omitBy, isEmpty } from 'lodash'
import classNames from 'classnames'

interface JobSearchProps {
  params?: ListParams<Job>
  setResults: (params: []) => void
  usingSearch?: (value: boolean) => void
  setLoading: (value: boolean) => void
}

export function JobSearch(props: JobSearchProps) {
  const [location, setLocation] = useState({ city: '', state: '' })
  const [locationQuery, setLocationQuery] = useState('')
  const role = useTextInput('')

  const [remote, setRemote] = useState(false)

  const fullTime = useCheckedInput(false)
  const partTime = useCheckedInput(false)
  const contract = useCheckedInput(false)
  const internship = useCheckedInput(false)

  const [options, setOptions] = useState<any>([])

  const debouncedQuery: string = useDebouncedInput(role.value || '', 250)

  function handleLocationSelect({ city, state }: { city: string; state: string }) {
    const value = city + ', ' + state
    setLocation({ city, state })
    return value
  }

  useEffect(() => {
    locationService
      .get('/locations/autocomplete', {
        input: locationQuery
      })
      .then(({ predictions }: any) => {
        setOptions(
          predictions.map(({ terms }: any) => {
            const city = terms[0].value
            const state = terms[1].value

            return { key: city + ', ' + state, value: { city, state } }
          })
        )
      })
      .finally(() => {})
  }, [locationQuery])

  useEffect(() => {
    props.setLoading(true)
    const typeArr = []

    if (fullTime.checked) typeArr.push('full_time')
    if (partTime.checked) typeArr.push('part_time')
    if (contract.checked) typeArr.push('contract')
    if (internship.checked) typeArr.push('internship')

    const params = {
      title: debouncedQuery,
      remote: remote ? remote : '',
      location: location.city ? location : '',
      type: typeArr.join(','),
      ...props.params
    }

    const cleanParams = omitBy(params, (v) => isUndefined(v) || isNull(v) || v === '')

    if (isEmpty(cleanParams)) {
      props.setResults([])
      props.setLoading(false)
      if (props.usingSearch) props.usingSearch(false)
    } else {
      if (props.usingSearch) props.usingSearch(true)
      jobService
        .list(cleanParams)
        .then((results: any) => {
          props.setResults(results)
        })
        .finally(() => props.setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedQuery,
    location,
    fullTime.checked,
    partTime.checked,
    contract.checked,
    internship.checked,
    remote
  ])

  return (
    <div className='job-search container border border-black rounded-xl flex flex-col mt-8 shadow-search lg:mt-10 lg:flex-row lg:rounded-3xl'>
      <div className=' border-gray-500 border-b py-5 px-4 lg:px-5 lg:border-r lg:border-b-0 lg:w-1/3 xl:w-2/5'>
        <div className='input-label'>What are you looking for?</div>
        <TextInput placeholder='Full Stack Engineer...' {...role} />
      </div>
      <div className='border-gray-500 border-b py-5 px-4 lg:px-5 lg:border-r lg:border-b-0 lg:w-1/3 xl:w-2/5'>
        <div className='input-label w-auto flex space-x-1 items-center'>
          Where are you looking?{' '}
          {!props.params?.location?.city && (
            <span className='font-normal relative flex items-center mx-0.5'>
              or{' '}
              <button
                className={classNames(
                  'absolute left-5 bg-gray-200 p-1 rounded-md leading-none',
                  remote && 'bg-primary'
                )}
                onClick={() => setRemote((prev) => !prev)}
              >
                Remote
              </button>
            </span>
          )}
        </div>
        <AutocompleteInput
          placeholder={
            remote
              ? 'Remote'
              : props.params?.location?.city
              ? props.params?.location.city + ', ' + props.params?.location.state
              : 'The North Pole...'
          }
          disabled={props.params?.location?.city || remote}
          setQuery={(value) => setLocationQuery(value)}
          handleSelect={handleLocationSelect}
          options={options}
        />
      </div>
      <div className='grid grid-cols-2 content-center py-5 px-4 gap-y-3 lg:px-5 lg:py-0 lg:w-1/3 xl:w-1/4'>
        <CheckboxInput label='Full-Time' {...fullTime} />
        <CheckboxInput label='Part-Time' {...partTime} />
        <CheckboxInput label='Contract' {...contract} />
        <CheckboxInput label='Internship' {...internship} />
      </div>
    </div>
  )
}
