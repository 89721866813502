import { Service, useList, useRead, SWRConfiguration } from 'api/common'
import type { Job, ListParams, ReadParams } from './types'
import { jobsSite } from 'lib/env'

export type { Job }

export class JobService extends Service<Job> {
  constructor() {
    super('/jobs')
  }

  override read(id: string, params?: ReadParams) {
    return super.read(id, { ...params, sites: jobsSite })
  }

  override list(params?: ListParams<Job>) {
    return super.list({ ...params, sites: jobsSite })
  }
}

export const jobService = new JobService()

export function useJobs(params?: ListParams<Job>, config?: SWRConfiguration) {
  return useList(jobService, params, config)
}

export function useJob(id: string, params?: ReadParams, config?: SWRConfiguration) {
  return useRead(jobService, id, params, config)
}
